import React from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const propTypes = {
  node: PropTypes.object.isRequired,
};

const BlogListCard = ({ node }) => {
  const title = node.frontmatter.title || node.fields.slug;
  const image = getImage(node.frontmatter.cover);

  return (
    <>
      <div className="relative w-full">
        {/* Background card */}
        <div class="absolute w-full h-full bg-gradient-to-t from-gray-400 to-gray-300 shadow-md rounded-3xl transform -rotate-3" />

        {/* Image card */}
        <Link to={node.fields.slug}>
          <div className="relative z-10 m-1 rounded-3xl shadow-xl bg-white dark:bg-gray-200 transform hover:rotate-3 transition duration-500 ease-in-out">
            <div className="grid grid-cols-1">
              <div className="flex flex-wrap content-end z-10 col-start-1 row-start-1 px-4 pb-3 bg-gradient-to-t from-black">
                <p className="text-sm font-medium text-white mb-1 w-full">
                  {node.frontmatter.date}
                </p>
                <h2 className="text-xl font-semibold text-white w-full">
                  {title}
                </h2>
              </div>
              <div className="col-start-1 row-start-2 px-4">
                <div className="flex items-center text-sm font-medium my-5">
                  <div
                    className="truncate text-black"
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </div>
              </div>
              <div className="col-start-1 row-start-1 flex">
                <div className="grid grid-cols-3 grid-rows-2 gap-2">
                  <div className="relative col-span-3 row-span-2">
                    {image ? (
                      <GatsbyImage
                        image={image}
                        alt={title}
                        className="inset-0 object-cover bg-gray-100 rounded-t-3xl"
                      />
                    ) : (
                      <StaticImage
                        src="../assets/default-blog-cover.jpg"
                        alt="No cover photo"
                        placeholder="blurred"
                        layout="constrained"
                        width={1024}
                        aspectRatio={1}
                        className="inset-0 object-cover bg-gray-100 rounded-t-3xl"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

BlogListCard.propTypes = propTypes;

export default BlogListCard;
